import React, {useEffect} from "react";
import './Overview.scss';
import NewsTile from "../mainpage/NewsTile";
import SidebarItem from "../sidebar/SidebarItem";
import SmallNewsTile from "../mainpage/SmallNewsTile";
import type {News} from "../model/News";
import {Helmet} from "react-helmet";
import MyWordCloud from "../sidebar/MyWordCloud";
import GoogleSearch from "../sidebar/GoogleSearch";
import TopCoins from "../sidebar/TopCoins";
import BigSidebarItem from "../sidebar/BigSidebarItem";

type OverviewProps = {
    topic: string,
    path: string,
    pageContext: {
        topic: string,
        data: News[],
        wordCloud: [string, number][],
    };
}

function Overview(props: OverviewProps) {
    const topic = props.pageContext.topic;
    const relevant = props.pageContext.data
        .filter((it, index) => index > 12)
        .sort((a, b) => a.relevance - b.relevance)
        .reverse()
        .slice(0, 10);
    const news = props.pageContext.data.slice(0, 12);

    useEffect(() => {
        if (window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            const node = document.getElementById("light");
            if (node) node.remove();
        } else {
            const node = document.getElementById("dark");
            if (node) node.remove();
        }
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);

    return <div className={"overview"}>
        <Helmet>
            <meta charSet="utf-8"/>
            <title>BtcLeaks | {topic} and Crypto News</title>
            <link rel="canonical" href={`https://btcleaks.com/${topic}`}/>
            <meta name="description"
                  content={`The latest news and informations about Crypto, Bitcoin and ${topic}`}/>
            <meta name="keywords" content={`${topic}, Crypto, Bitcoin, News, Overview`}/>
            <meta property="og:title" content={`BtcLeaks | ${topic} and Crypto News`}/>
            <meta property="og:description"
                  content={`The latest news and informations about Crypto, Bitcoin and ${topic}`}/>
            <meta property="og:image" content="http://btcleaks.com/img_00148.png"/>
            <meta property="og:url" content={`https://btcleaks.com/${topic}`}/>
            <meta property="og:type" content="website"/>
            <meta property="og:locale" content="en_GB"/>
            <meta property="og:image:width" content="1200"/>
            <meta property="og:image:height" content="530"/>
            <meta property="og:image:alt" content={"BtcLeaks.com"}/>
            <meta property="og:image:secure_url" content="https://btcleaks.com/img_00148.png"/>
            <meta property="og:image:type" content="image/png"/>
            <meta property="og:site.name" content={"btcLeaks.com"}/>
            <meta property="article:publisher" content="https://btcLeaks.com"/>
            <meta property="article:author" content="BtcLeaks.com"/>
            <meta name="twitter:site" content="@BtcLeaks"/>
            <meta name="twitter:creator" content="Saitama"/>
            <meta name="twitter:card" content="summary"/>
            <script type="application/ld+json">{`
                {
                    "mainEntityOfPage": { 
                        "@type": "WebPage",
                        "@id": "btcleaks.com"
                    },
                    "description": "The latest news and informations about Crypto, Bitcoin and ${topic}",
                    "dateModified": "${new Date().toISOString()}",
                    "datePublished": "${new Date().toISOString()}",
                    "headline": "BtcLeaks | ${topic} and Crypto News",
                    "@context": "http://schema.org",
                    "@type": "WebPage",
                    "publisher": {
                        "@type": "Organization",
                        "name": "btcLeaks.com",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "https://btcleaks.com/image_00106.png"
                        }   
                    }
                }`
            }</script>
        </Helmet>
        <div className={"contentWrapper"}>
            <div className="column1">
                <div className="content">
                    <div className="gcse-searchresults"/>
                    {news && news.length > 0
                        ? <section className="mainSection">
                            <h1>Recent {topic} News</h1>
                            {news
                                .filter((it, index) => index < 4)
                                .map((it, i) => <NewsTile news={it} key={"over_" + i}/>)}
                            {news
                                .filter((it, index) => index > 4 && index < 6)
                                .map((it, i) => <SmallNewsTile news={it} key={"overw_" + i}/>)}
                            <article style={{marginBottom: "24px", overflow: "hidden"}}>
                                <ins className="adsbygoogle" id={"dark"}
                                     style={{display: "block"}}
                                     data-ad-format="fluid"
                                     data-ad-layout-key="-gx-y+aj-9s-iz"
                                     data-ad-client="ca-pub-6013495598121737"
                                     data-ad-slot="1313212288"/>
                                <ins className="adsbygoogle" id={"light"}
                                     style={{display: "block"}}
                                     data-ad-format="fluid"
                                     data-ad-layout-key="-hf-1a+ae-9a-gl"
                                     data-ad-client="ca-pub-6013495598121737"
                                     data-ad-slot="9324119159"/>
                            </article>
                            {news
                                .filter((it, index) => index > 5 && index < 13)
                                .map((it, i) => <SmallNewsTile news={it} key={"overw_" + i}/>)}
                        </section>
                        : <section className={"nothingFound"}>
                            No news about {topic} found.
                        </section>
                    }
                </div>
            </div>
            <aside className={"column2"}>
                <TopCoins/>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        {relevant.length > 0 &&
                            <div className={"sidebarHeadline"}>Most views</div>}
                        {relevant.slice(0, 1)
                            .map((it, i) => <BigSidebarItem key={"rele_big" + i} news={it}/>)}
                        {relevant.slice(1, 100)
                            .map((it, i) => <SidebarItem key={"rele_" + i} news={it}/>)}
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <ins className="adsbygoogle"
                             style={{display: "block"}}
                             data-ad-client="ca-pub-6013495598121737"
                             data-ad-slot="9302285469"
                             data-ad-format="auto"
                             data-full-width-responsive="true"/>
                    </div>
                </div>
                <div className="sidebar">
                    <div className={"sidebarGroup"}>
                        <div className={"sidebarHeadline"}>
                            <span>Search</span>
                        </div>
                        <GoogleSearch/>
                        <MyWordCloud wordCloud={props.pageContext.wordCloud}/>
                    </div>
                </div>
            </aside>
        </div>
    </div>;
}

export default Overview;
